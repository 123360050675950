import React from "react";
import DayPicker from "react-day-picker";
import { css } from "emotion";
import "react-day-picker/lib/style.css";

const DatePicker = ({ onDayClick, selectedDays }) => {
  return (
    <div
      className={css`
        width: 100%;
        .DayPicker {
          font-size: 1.6rem;
          width: 100%;
        }
      `}
    >
      <DayPicker
        numberOfMonths={2}
        onDayClick={onDayClick}
        selectedDays={selectedDays}
      />
    </div>
  );
};

export default DatePicker;
