import React from "react";
import { css } from "emotion";

const SubHeading = ({ children }) => (
  <div
    className={css`
      color: rgba(60, 60, 60, 0.9);
      font-size: 1.6rem;
      margin-bottom: 20px;
    `}
  >
    {children}
  </div>
);

export default SubHeading;
