import React from "react";
import { css } from "emotion";

const Heading = ({ children }) => (
  <div
    className={css`
      color: rgba(60, 60, 60, 1);
      font-size: 1.8rem;
      margin-bottom: 20px;
      text-align: center;
    `}
  >
    {children}
  </div>
);

export default Heading;
