import React, { Component } from "react";
import moment from "moment-timezone";
import Loading from "react-loading";
import sanitizeHtml from "sanitize-html";
import { css } from "emotion";

import Button from "components/Button";
import Heading from "components/Heading";
import { formatPhoneNumber } from "utils/helpers";

const Load = () => (
  <div
    className={css`
      display: flex;
      justify-content: center;
      padding: 10px;
      width: 100%;
    `}
  >
    <Loading type="bars" color="#afafaf" />
  </div>
);

class Confirm extends Component {
  state = {
    confirmed: false,
    loading: false,
    verified: false,
    smsTransactional:
      this.props.initialUserOptInPreferences &&
      this.props.initialUserOptInPreferences.sms_transactional
        ? this.props.initialUserOptInPreferences.sms_transactional
        : false,
  };

  onBookError() {
    this.setState({ loading: false });
    alert(
      "Uh oh, something went wrong when trying to book your appointment. Please refresh the page and give it another try."
    );
  }

  onReadOnlyError() {
    this.setState({ loading: false });
    alert(
      "Sorry, we're currently unable to take bookings online. Please try again later"
    );
  }

  render() {
    const { input, location, onBook, orgPreferences } = this.props;
    const { confirmed, loading } = this.state;

    return (
      <div
        className={css`
          padding-bottom: 15px;
        `}
      >
        <Heading>
          <div>Confirm Your Appointment</div>
          {!confirmed ? (
            <div
              className={css`
                color: rgb(197, 40, 61);
                text-transform: uppercase;
              `}
            >
              Click Button Below to Book
            </div>
          ) : (
            <div
              className={css`
                color: rgb(41, 165, 115);
                font-weight: 600;
              `}
            >
              BOOKED
            </div>
          )}
        </Heading>

        <div
          className={css`
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 20px;
            margin-bottom: 20px;
            text-align: center;
            width: auto;
          `}
        >
          <div>
            {input.givenName} {input.familyName}
          </div>
          <div
            className={css`
              font-weight: 700;
              font-size: 2.4rem;
            `}
          >
            {moment(input.datetime)
              .tz(location.timezone)
              .format("dddd, MMMM D, YYYY [at] h:mm a")}
          </div>
          <div
            className={css`
              margin: 15px 0;
            `}
          >
            at
          </div>
          <div>
            <span
              className={css`
                font-weight: 600;
              `}
            >
              {location.display_name || location.name}
            </span>
            <br />
            {location.address}
            <br />
            {formatPhoneNumber(location.phone_number)}
          </div>
        </div>
        {!confirmed && input.appointmentTypeId === 389 && (
          <div>
            <p>
              All pets should present in good health. If your pet is not current
              on the necessary vaccines, we can help you schedule an appointment
              with our Care team.
            </p>

            <p>
              Dogs: We request that you provide proof of vaccination for Rabies,
              DHPP, and Bordetella from your veterinarian.
            </p>

            <p>
              Cats: We request that you provide proof of vaccination for Rabies
              and FVRCP from your veterinarian.
            </p>

            <p>Rabies is not required for pets less than 4 months of age</p>

            <p>
              <input
                type="checkbox"
                id="verification"
                onClick={() =>
                  this.setState({ verified: !this.state.verified })
                }
              />{" "}
              I agree to bring these documents to my visit or to email them to{" "}
              {location.email || "us"} before your visit.
            </p>
          </div>
        )}
        {!confirmed && input.appointmentTypeId === 449 && (
          <div>
            <p>
              All pets should present in good health. We request that you
              provide proof of vaccination for Rabies, DHPP, and Bordetella from
              your veterinarian, as well as a hard copy of your dog's Rabies
              Certificate to be kept on file at the Center.
            </p>

            <p>
              <input
                type="checkbox"
                id="verification"
                onClick={() =>
                  this.setState({ verified: !this.state.verified })
                }
              />{" "}
              I agree to bring these documents to my visit or to email them to{" "}
              {location.email || "us"} before my visit.
            </p>
          </div>
        )}
        <div
          className={css`
            text-align: center;
            margin: 15px 0;
          `}
        >
          You'll be emailed a confirmation email with the details. Please call
          our office if you need to make any updates.
        </div>

        {!confirmed &&
          orgPreferences &&
          orgPreferences["org.enable_comm_opt_in"] &&
          orgPreferences["org.online_booking_comm_agreement_policy"] && (
            <div
              className={css`
                margin: 15px 15px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                `}
              >
                <input
                  type="checkbox"
                  checked={this.state.smsTransactional}
                  onChange={() => {
                    this.setState({
                      smsTransactional: !this.state.smsTransactional,
                    });
                  }}
                />
                <div
                  className={css`
                    color: rgba(60, 60, 60, 0.9);
                    margin: 15px 0;
                    text-align: center;
                    width: 100%;
                  `}
                >
                  {orgPreferences["org.transactional_sms_agreement_text"]}
                </div>
              </div>
              <div
                className={css`
                  margin: 15px 0;
                  text-align: center;
                `}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    orgPreferences["org.online_booking_comm_agreement_policy"],
                    {
                      allowedTags: ["a"],
                      allowedAttributes: {
                        a: ["href", "target"],
                      },
                    }
                  ),
                }}
              />
            </div>
          )}
        {loading ? (
          <Load />
        ) : confirmed ? (
          <div
            className={css`
              color: rgb(41, 165, 115);
              font-weight: 700;
              text-align: center;
            `}
          >
            Appointment Successfully Booked! See you soon!
          </div>
        ) : (
          <Button
            fullWidth
            onClick={async () => {
              if (
                !confirmed &&
                (input.appointmentTypeId === 499 ||
                  input.appointmentTypeId === 389) &&
                !this.state.verified
              ) {
                alert("You must agree to the verification prior to booking.");
                return;
              }
              if (this.state.loading) return false;
              this.setState({ loading: true });
              let result = await onBook({
                smsTransactional: this.state.smsTransactional,
              });

              if (result.ok && result.status === 200) {
                const response = await result.json();
                if (
                  response.data &&
                  response.data.ids &&
                  response.data.locationId
                ) {
                  let { ids, locationId } = response.data;
                  ids = ids.join(",");
                  this.setState({ confirmed: true, loading: false });
                  console.log("booked!");
                  window.parent.postMessage(
                    `YourVetBooked:${ids || ""}:${locationId || ""}`,
                    "*"
                  );
                } else this.onBookError();
              } else if (!result.ok && result.status === 503) {
                this.onReadOnlyError();
              } else this.onBookError();
            }}
          >
            Book Appointment
          </Button>
        )}
      </div>
    );
  }
}

export default Confirm;
