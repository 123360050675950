import React, { Component } from "react";
import { css, injectGlobal } from "emotion";

injectGlobal`
  ::placeholder {
    font-weight: 300;
  }
`;

class Input extends Component {
  constructor(props) {
    super(props);

    const value = props.value || "";
    const valid = this.props.validate ? this.props.validate(value) : false;

    this.state = { value, valid, init: false };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.autoFocus) this.focus();

    // let callback know of initial validation state
    if (this.props.onValidationChanged)
      this.props.onValidationChanged(this.state.valid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.value !== this.state.value ||
      prevProps.forceValidCheck !== this.props.forceValidCheck
    ) {
      let valid = this.state.valid;
      if (this.props.validate) valid = this.props.validate(this.state.value);

      if (valid !== this.state.valid) {
        this.setState({ valid: valid });

        if (this.props.onValidationChanged)
          this.props.onValidationChanged(valid);
      }

      if (!this.state.init) this.setState({ init: true });
    }
  }

  focus() {
    this.inputRef.current.focus();
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({ value: value });

    if (this.props.onChange) this.props.onChange(value);
  }

  render() {
    const { placeholder, type, pattern, errorMessage, min, max } = this.props;
    const { value, focus, init, valid } = this.state;
    const focused = focus || value !== "";
    const showValidationError = init && !valid;

    let borderBottomColor = "rgba(0, 0, 0, 0.2)";
    if (showValidationError) borderBottomColor = "rgb(255, 0, 0)";
    else if (focused) borderBottomColor = "rgba(0, 0, 0, 0.7)";

    return (
      <div>
        <div
          className={css`
            width: 100%;
            position: relative;
            background-color: #fff;
            padding: 1.3em 1em 0.3em;
            z-index: 0;
            border-bottom: 1px solid ${borderBottomColor};
            margin-bottom: 10px;
            transition: border-color 0.2s ease;
          `}
          onClick={() => this.focus()}
        >
          <input
            type={type || "text"}
            min={type === "number" ? min : null}
            max={type === "number" ? max : null}
            ref={this.inputRef}
            placeholder={focused ? placeholder : ""}
            value={value}
            onChange={(e) => this.handleChange(e)}
            pattern={pattern}
            className={css`
              outline: none;
              font: inherit;
              color: inherit;
              background-color: transparent;
              border: 0;
              box-shadow: none;
              width: 100%;
              z-index: 1;
            `}
            onFocus={() => this.setState({ focus: true })}
            onBlur={() => this.setState({ focus: false, init: true })}
          />
          <div
            className={css`
              position: absolute;
              font-weight: 300;
              z-index: -1;
              top: 1em;
              transition: font-size 0.2s ease, top 0.2s ease;

              ${focused && {
                fontSize: "0.7em",
                top: "0.6em",
              }}
            `}
          >
            {placeholder}
          </div>
        </div>
        <div
          className={css`
            color: rgba(255, 0, 0);
            font-size: 0.7em;
            display: ${showValidationError ? "block" : "none"};
          `}
        >
          {showValidationError && errorMessage ? errorMessage : "invalid data"}
        </div>
      </div>
    );
  }
}

export default Input;
