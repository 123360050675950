import React, { Component } from 'react'

const withForm = (WrappedComponent) => {
  return class Form extends Component {
    constructor(props) {
      super();

      this.state = {
        validationErrors: []
      };

      this.updateForm = this.updateForm.bind(this);
      this.resetForm = this.resetForm.bind(this);
      this.isFormValid = this.isFormValid.bind(this);
    }

    updateForm(field, valid) {
      let errors = this.state.validationErrors;
  
      if (valid) {
        const index = errors.indexOf(field);
  
        // remove validation error if it exists and the field is now valid
        if (index !== -1) {
          errors.splice(index, 1);
          this.setState({ validationErrors: errors })
        }
      }
      else {
        // add to list of validation errors
        errors.push(field);
        this.setState({ validationErrors: errors })
      }
    }

    resetForm() {
      this.setState({ validationErrors: [] })
    }

    isFormValid() {
      return this.state.validationErrors.length === 0;
    }

    render() {
      return <WrappedComponent updateForm={this.updateForm} isFormValid={this.isFormValid} resetForm={this.resetForm} {...this.props} />
    }
  }
}

export default withForm;