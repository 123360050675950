import React, { Component } from "react";
import { css } from "emotion";

class Options extends Component {
  render() {
    const { title, options, currentValue } = this.props;

    return (
      <div>
        <div
          className={css`
            font-weight: 600;
            margin-bottom: 10px;
          `}
        >
          {title}
        </div>
        <div
          className={css`
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            overflow: hidden;
          `}
        >
          {options.map((o) => (
            <div
              key={o.value}
              className={css`
                padding: 0.5em 1.4em;
                flex-basis: 50%;
                transition: background-color 0.2s ease, color 0.2s ease;
                min-width: 150px;
                flex-grow: 1;
                border: 1px solid rgba(0, 0, 0, 0.1);

                &:not(:first-child) {
                  border-left: 1px solid rgba(0, 0, 0, 0.1);
                }

                ${currentValue === o.value
                  ? {
                      backgroundColor: o.color || "rgb(35, 118, 229)",
                      color: "#FFF",
                    }
                  : {
                      "&:hover": {
                        backgroundColor: o.color
                          ? o.color + "88"
                          : "rgba(40, 122, 229, 0.6)",
                        color: "#FFF",
                        cursor: "pointer",
                      },
                    }}
              `}
              onClick={() => {
                if (currentValue !== o.value) this.props.onChange(o.value);
              }}
            >
              <div
                className={css`
                  font-weight: 700;
                  font-size: 1.6rem;
                `}
              >
                {currentValue === o.value && (
                  <span
                    className={css`
                      font-weight: 300;
                    `}
                  >
                    You Selected: &nbsp;
                  </span>
                )}
                {o.label}
              </div>
              <div
                className={css`
                  font-size: 1.4rem;
                  opacity: 0.8;
                `}
              >
                {o.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Options;
