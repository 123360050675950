import React from "react";
import Input from "components/Input";
import Select from "components/Select";
import Heading from "components/Heading";
import Button from "components/Button";
import { css } from "emotion";
import LocationSearchInput from "components/LocationSearchInput";
import withForm from "components/Form";
import { notEmpty, validEmail, validPhone } from "../utils/validate";

const Client = withForm(
  ({ updateForm, isFormValid, input, onChange, onNext, org }) => {
    return (
      <div>
        <Heading>
          Fill in your information below to confirm the appointment.
        </Heading>
        <div
          className={css`
            margin-bottom: 30px;
          `}
        >
          <Input
            placeholder="Your Email"
            value={input.email}
            type="text"
            onChange={(v) => onChange({ email: v })}
            validate={(v) => validEmail(v)}
            errorMessage="A valid email is required"
            onValidationChanged={(valid) => updateForm("email", valid)}
          />
          <div
            className={css`
              display: flex;
              > div {
                flex-grow: 1;
              }
              > div:last-child {
                margin-left: 10px;
              }
              > div:first-child {
                margin-right: 10px;
              }
            `}
          >
            <Input
              placeholder="Your First Name"
              value={input.givenName}
              onChange={(v) => onChange({ givenName: v })}
              validate={(v) => notEmpty(v)}
              errorMessage="First name required"
              onValidationChanged={(valid) => updateForm("givenName", valid)}
            />
            <Input
              placeholder="Your Last Name"
              value={input.familyName}
              onChange={(v) => onChange({ familyName: v })}
              validate={(v) => notEmpty(v)}
              errorMessage="Last name required"
              onValidationChanged={(valid) => updateForm("familyName", valid)}
            />
          </div>
          <Input
            placeholder="Your Phone Number"
            value={input.phoneNumber}
            onChange={(v) => onChange({ phoneNumber: v })}
            validate={(v) => validPhone(v, "US")}
            errorMessage="Valid phone number required"
            onValidationChanged={(valid) => updateForm("phoneNumber", valid)}
          />
          <LocationSearchInput
            placeholder="Your Address"
            value={input.address || ""}
            onChange={(v) => onChange({ address: v })}
            validate={(v) => notEmpty(v)}
            errorMessage="Address required"
            onValidationChanged={(valid) => updateForm("address", valid)}
          />
          {org && (org.id === 50 || org.id === 12) && (
            <div>
              <div
                className={css`
                  font-weight: 600;
                  margin-bottom: 10px;
                  margin-top: 20px;
                `}
              >
                How did you hear about us?
              </div>
              <Select
                onChange={(v) => onChange({ clientReferralSourceId: v })}
                icon="favorite"
                value={input.clientReferralSourceId}
                options={[
                  { value: "75", label: "Google/Website" },
                  { value: "76", label: "Facebook" },
                  { value: "81", label: "Instagram" },
                  { value: "74", label: "Yelp" },
                  { value: "78", label: "Referral" },
                  { value: "77", label: "Event" },
                  { value: "79", label: "Direct Mail" },
                  { value: "80", label: "Print Ad" },
                  { value: "83", label: "Other" },
                ]}
              />
            </div>
          )}
        </div>
        <Button
          fullWidth
          disabled={
            !isFormValid() ||
            (org && org.id === 50 && !input.clientReferralSourceId)
          }
          onClick={() => {
            onNext();
          }}
        >
          Next
        </Button>
      </div>
    );
  }
);

export default Client;
