import React, { useState } from "react";
import { css } from "emotion";

import SubHeading from "components/SubHeading";
import Options from "components/Options";
import Input from "components/Input";
import PlayQuestions from "components/PlayQuestions";
import withForm from "components/Form";
import { validateReason } from "utils/validate";
import { formatPhoneNumber } from "utils/helpers";

import DatetimeSelect from "./DatetimeSelect";

class HeaderInfo extends React.Component {
  constructor(props) {
    super(props);
    this.el = null;

    this._clickNumber = () => this.clickNumber();
  }

  componentDidMount() {
    const el = document.getElementById("location-phone-number");
    this.el = el;
    if (el) {
      this.el.addEventListener("click", this._clickNumber);
      this.el.addEventListener("touchstart", this._clickNumber);
    }
  }

  componentWillUnmount() {
    if (this.el) {
      this.el.removeEventListener("click", this._clickNumber);
      this.el.removeEventListener("touchstart", this._clickNumber);
    }
  }

  clickNumber() {
    window.gtag("event", "click_to_call", {
      locationId: this.props.location.id,
      event_category: "vetspire_booking",
      event_label: `id:${this.props.location.id}`,
    });
  }

  render() {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: this.props.header,
        }}
      />
    );
  }
}

const Appointment = withForm(
  ({
    sameDayOnlyAppointments,
    updateForm,
    resetForm,
    location,
    appointmentTypes,
    input,
    onChange,
    onNext,
    preferences,
    orgSlug,
  }) => {
    const [forceValidCheck, setForceValidCheck] = useState(false);

    const getReasonError = (reason, type) => {
      if (type === 43 && reason && reason.length >= 5)
        return "You've entered in details for a sick visit. Please select Sick visit instead before continuing.";
      else return "Please enter a reason for visit with at least 5 characters.";
    };

    let additionalPrompts = null;
    if (input.appointmentTypeId === 389 || input.appointmentTypeId === 1)
      additionalPrompts = (
        <PlayQuestions
          input={input}
          phoneNumber={location.phone_number}
          onChange={(updates) => onChange(updates)}
        />
      );
    else if (input.appointmentTypeId !== 449)
      additionalPrompts = (
        <div>
          <div
            className={css`
              font-weight: 600;
              margin-bottom: 10px;
            `}
          >
            How can we help?
          </div>
          <Input
            placeholder="Reason for Visit"
            value={input.reason}
            onChange={(v) => onChange({ reason: v })}
            validate={(v) => validateReason(input.appointmentTypeId, v)}
            errorMessage={getReasonError(input.reason, input.appointmentTypeId)}
            onValidationChanged={(valid) => updateForm("reason", valid)}
            forceValidCheck={forceValidCheck}
          />
        </div>
      );

    const replaceHeaders = (h) => {
      const formattedPhone =
        formatPhoneNumber(location.phone_number) ?? "our phone number";
      return h
        .replace(
          "{{location.name}}",
          `<strong>${
            location.display_name || location.name || "our location"
          }</strong>`
        )
        .replace(
          "{{location.phone_number}}",
          `<strong><a href="tel:${location.phone_number}" id="location-phone-number">${formattedPhone}</a></strong>`
        );
    };

    const header =
      preferences?.["org.online_book_header"] ||
      "We look forward to welcoming you at {{location.name}} soon. Please select the most convenient appointment or give us a call at {{location.phone_number}} to speak with one of our staff members.";

    const footer =
      preferences?.["org.online_book_footer"] ||
      "If you don't see a time that works for you, please give us a call at {{location.phone_number}} so that we can find a time that works for you.";

    return (
      <div>
        <SubHeading>
          <HeaderInfo location={location} header={replaceHeaders(header)} />
        </SubHeading>
        <div
          className={css`
            margin: 20px 0;
            > div {
              margin-right: 20px;
            }
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          `}
        >
          <Options
            title="What type of appointment do you need?"
            options={(appointmentTypes || [])
              .slice()
              .filter((t) => {
                if (t.name === "Play") return location.enable_daycare !== false;
                if (t.name === "Stay")
                  return location.enable_boarding !== false;
                if (t.name === "Style")
                  return (location.tags || []).some((t) => t === "Style");
                return true;
              })
              .sort((a, b) => {
                if (a.is_default && !b.is_default) {
                  return -1;
                } else if (!a.is_default && b.is_default) {
                  return 1;
                } else {
                  const aName = (a.name || "").toLowerCase();
                  const bName = (b.name || "").toLowerCase();

                  return aName.localeCompare(bName);
                }
              })
              .map((t) => ({
                value: t.id,
                label: t.name,
                description: t.description,
                color: t.color,
              }))}
            currentValue={input.appointmentTypeId}
            onChange={(t) => {
              // reset validation errors
              // hacky solution to update reason due to poor form design
              onChange({ appointmentTypeId: t });
              setForceValidCheck(!forceValidCheck);
              resetForm();
            }}
          />
        </div>
        {additionalPrompts}
        <DatetimeSelect
          sameDayOnlyAppointments={sameDayOnlyAppointments}
          locationId={location.id}
          orgSlug={orgSlug}
          timezone={location.timezone}
          selectedDatetime={input.datetime}
          phoneNumber={location.phone_number}
          appointmentTypeId={input.appointmentTypeId}
          openDate={location.open_date}
          onDateTimeSelect={(updates) => {
            if (validateReason(input.appointmentTypeId, input.reason)) {
              onChange(updates);
              onNext();
            } else {
              alert("Please enter a reason for visit before continuing.");
              setForceValidCheck(true);
              resetForm();
            }
          }}
          onNumberOfPetsChange={(n) => {
            onChange({ numberOfPets: n });
          }}
          numberOfPets={input.numberOfPets}
          input={input}
          footer={replaceHeaders(footer)}
        />
      </div>
    );
  }
);

export default Appointment;
