import React from "react";
import Options from "components/Options";
import { css } from "emotion";

const PlayQuestions = ({ input, onChange, phoneNumber }) => (
  <div
    className={css`
      margin-bottom: 30px;
    `}
  >
    <Options
      title="What species is your pet?"
      options={[
        {
          value: "canine",
          label: "Dog"
        },
        {
          value: "feline",
          label: "Cat"
        }
      ]}
      currentValue={input.species}
      onChange={t => {
        if (t === "feline") {
          alert(
            `We're sorry, but we cannot book cat grooming visits online at this time. Please call us directly at ${phoneNumber} to book your cat's grooming visit.`
          );
        } else {
          onChange({ species: t });
        }
      }}
    />
    {input.species === "canine" ? (
      <div>
        <div
          className={css`
            margin-top: 15px;
          `}
        >
          <Options
            title="What type of style service would you like like?"
            options={[
              {
                value: "FULL_GROOM_HAIRCUT",
                label: "Haircut, Bath, and Blowout"
              },
              {
                value: "FULL_GROOM",
                label: "Bath and Blowout"
              },
              {
                value: "REFRESH",
                label: "Touch-up — a trim and refresh"
              }
            ]}
            currentValue={input.groomingService}
            onChange={t => onChange({ groomingService: t })}
          />
        </div>
        <div
          className={css`
            margin-top: 15px;
          `}
        >
          <Options
            title="Please select the weight range of your pet:"
            options={[
              {
                value: "EXTRA_SMALL",
                label: "Less than 13 lbs"
              },
              {
                value: "SMALL",
                label: "13-25 lbs"
              },
              {
                value: "MEDIUM",
                label: "26-40 lbs"
              },
              {
                value: "LARGE",
                label: "41-60 lbs"
              },
              {
                value: "EXTRA_LARGE",
                label: "61-85 lbs"
              },
              {
                value: "GIANT",
                label: "86+ lbs"
              }
            ]}
            currentValue={input.weightRange}
            onChange={t => onChange({ weightRange: t })}
          />
        </div>
        <div
          className={css`
            margin-top: 15px;
          `}
        >
          <Options
            title="What type of coat does your pet have?"
            currentValue={input.coat}
            onChange={t => onChange({ coat: t })}
            options={[
              {
                value: "SMOOTH",
                label: "Short coat",
                description: "Smooth short hair that lies close to the body"
              },
              {
                value: "WIRY",
                label: "Wiry hair",
                description: "e.g. Jack Russell Terrier"
              },
              { value: "CURLY", label: "Curly hair" },
              { value: "LONG", label: "Fluffy or Long Coat" }
            ]}
          />
        </div>
      </div>
    ) : input.species === "feline" ? (
      <div
        className={css`
          > div {
            margin-top: 15px;
          }
        `}
      >
        <Options
          title="What type of style service would you like like?"
          currentValue={input.groomingService}
          onChange={t => onChange({ groomingService: t })}
          options={[
            { value: "BATH", label: "Only Bath" },
            { value: "HAIRCUT", label: "Only Haircut" },
            { value: "BATH+HAIRCUT", label: "Bath & Haircut" }
          ]}
        />
        <Options
          title="What type of coat does your pet have?"
          currentValue={input.coat}
          onChange={t => onChange({ coat: t })}
          options={[
            {
              value: "SMOOTH",
              label: "Smooth or short coat"
            },
            {
              value: "MEDIUM",
              label: "Medium coat"
            },
            { value: "LONG", label: "Fluffy or Long Coat" }
          ]}
        />
      </div>
    ) : (
      <div />
    )}
  </div>
);

export default PlayQuestions;
