import React from "react";
import { css } from "emotion";

const Button = ({ children, onClick, fullWidth, disabled }) => (
  <button
    className={css`
      background: transparent;
      border: none;
      outline: none;
      font: inherit;
      background-color: rgb(35, 118, 229);
      color: rgba(255, 255, 255, 0.9);
      padding: 0.6em 1.6em;
      height: auto;
      border-radius: 4px;
      font-size: 0.93em;
      position: relative;
      text-align: center;
      font-weight: 700;
      opacity: ${disabled ? "0.3" : 1};
      width: ${fullWidth ? "100%" : "auto"};
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
        0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.08);
      transition: background-color 0.2s ease, opacity 0.2s ease, color 0.2s ease,
        box-shadow 0.2s ease, transform 0.2s ease;

      &:hover {
        background-color: #4f92ea;
        box-shadow: 0 1px 1px 0 rgba(66, 133, 244, 0.45), 0 1px 3px 1px,
          rgba(66, 133, 244, 0.45);
        cursor: ${disabled ? "default" : "pointer"};
        color: rgba(255, 255, 255, 1);
      }
    `}
    onClick={
      disabled
        ? () => alert("Please fill in all the information to continue.")
        : () => onClick()
    }
  >
    {children}
  </button>
);

export default Button;
