import React, { useState } from "react";
import { css, injectGlobal } from "emotion";
import Transition from "react-transition-group/Transition";
import Booker from "./containers/Booker";
import queryString from "./deps/queryString";
import { apiGet } from "./utils/api";

import "normalize.css";

injectGlobal`
  * {
    box-sizing: border-box;
  }

  html {
    font-family: "Source Sans Pro", "Helvetica", sans-serif;
    font-size: 62.5%;
    line-height: 1.4;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-size: 1.8rem;
  }
`;

function teardown() {
  window.parent.postMessage("YourVetTeardown", "*");
}

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  appearing: { opacity: 0 },
  appeared: { opacity: 1 },
  entered: { opacity: 1 },
};

const defaultModalStyle = {
  transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
  opacity: 0,
  transform: "translateY(-100px)",
};

const transitionModalStyles = {
  appearing: { opacity: 0, transform: "translateY(-100px)" },
  appeared: { opacity: 1, transform: "translateY(0)" },
  entered: { opacity: 1, transform: "translateY(0)" },
};

const App = () => {
  const search = window.location.search;
  const initialState = queryString.parse(search.slice(1));
  const [locationId, setLocationId] = useState(initialState.locationId);

  if (!initialState.org || initialState.org === "") {
    teardown();
    window.location.href = "https://your.vet/";
  }

  const isEmbed = initialState.embed === "true";

  if (
    !locationId &&
    initialState.customAttributeName &&
    initialState.customAttributeValue
  ) {
    apiGet(
      `/locations/get_by_custom_attribute?org=${initialState.org}&customAttributeName=${initialState.customAttributeName}&customAttributeValue=${initialState.customAttributeValue}`
    ).then((r) => {
      if (r.data) {
        setLocationId(r.data.id);
      }
    });
  }

  return (
    <Transition in={true} appear={true} timeout={0}>
      {(state) => (
        <div
          className={
            isEmbed
              ? ""
              : css`
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100vh;
                  overflow-y: auto;
                  background-color: rgba(54, 62, 73, 0.8);
                  padding: 10px 5px 5px 5px;
                `
          }
          style={
            isEmbed
              ? {}
              : {
                  ...defaultStyle,
                  ...transitionStyles[state],
                }
          }
        >
          <Transition
            in={true}
            appear={isEmbed ? false : true}
            timeout={isEmbed ? 0 : duration}
          >
            {(state) => (
              <div
                className={css`
                  width: ${isEmbed ? "100%" : "700px"};
                  max-width: 100%;
                  margin: 0 auto 30px;
                `}
                style={{
                  ...defaultModalStyle,
                  ...transitionModalStyles[state],
                }}
              >
                {!isEmbed && (
                  <div
                    className={css`
                      color: rgba(255, 255, 255, 0.7);
                      cursor: pointer;
                      margin-bottom: 2px;
                      text-align: right;
                    `}
                    onClick={() => teardown()}
                  >
                    <i className="material-icons">close</i>
                  </div>
                )}
                <div
                  className={css`
                    background-color: #fff;
                    border-radius: ${isEmbed ? 0 : "8px"};
                    min-height: 60px;
                    box-shadow: ${isEmbed
                      ? "none"
                      : "0 10px 30px rgba(0, 0, 0, 0.2)"};
                    transition: height 0.2s ease;
                  `}
                  onClick={(e) => e.stopPropagation()}
                >
                  {locationId && (
                    <Booker
                      {...{ ...initialState, locationId }}
                      isEmbed={isEmbed}
                    />
                  )}
                </div>
              </div>
            )}
          </Transition>
        </div>
      )}
    </Transition>
  );
};

export default App;
