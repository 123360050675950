import React, { Component } from "react";
import { css } from "emotion";

class Select extends Component {
  render() {
    return (
      <div
        className={css`
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          padding: 6px 8px 6px 13px;
          font-weight: 700;
          display: inline-flex;
          align-items: center;
          position: relative;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: rgba(18, 173, 253, 0.1);
            cursor: pointer;
          }

          ${this.props.open && {
            color: "rgb(18, 173, 253)",
          }}
        `}
        onClick={() => this.props.onClick && this.props.onClick()}
      >
        {this.props.icon && (
          <i
            className="material-icons"
            style={{ fontSize: "1.8rem", marginRight: "7px" }}
          >
            {this.props.icon}
          </i>
        )}
        {(() => {
          if (this.props.options) {
            const opt = this.props.options.find(
              (o) => o.value === this.props.value
            );
            if (opt !== undefined) return opt.label;
            else
              return (
                <span
                  className={css`
                    font-weight: 400;
                  `}
                >
                  {this.props.placeholder || "Select"}
                </span>
              );
          } else {
            return this.props.value;
          }
        })()}
        <i
          className="material-icons"
          style={{
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          {this.props.open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
        {this.props.options && (
          <select
            className={css`
              position: absolute;
              width: 100%;
              opacity: 0;
              height: 100%;
              cursor: pointer;
            `}
            onChange={(e) => this.props.onChange(e.target.value)}
          >
            <option key="disabled" value={null}>
              Select:
            </option>
            {this.props.options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }
}

export default Select;
