import parsePhoneNumber from "libphonenumber-js";
import { SICK_WORDS } from "constants/values";

// ensure value isn't empty
const notEmpty = value => {
  return !(!value || value.length === 0 || value === '');
}

const validNumber = (value, min, max) => {
  return !isNaN(value) && value >= min && value <= max;
}

// regex validate email
const validEmail = value => {
  return /.@./.test(value);
}

// possible valid phone number w/ country code
const validPhone = (value, countryCode) => {
  const number = parsePhoneNumber(value, countryCode);
  return (number !== undefined && number.isPossible());
}

// validate appointment reason
const validateReason = (appointmentTypeId, reason) => {
  const asLower = (reason || "").toLowerCase()

  // check for reason of length >= 5 characters
  if (appointmentTypeId !== 389 && appointmentTypeId !== 449 && appointmentTypeId !== 1 &&
    (!reason || reason === "" || reason.length < 5 || !/\S/.test(reason)))
    return false;

  // ensure reason doesn't have "sick" words (should be a sick visit instead of wellness)
  else if (appointmentTypeId === 43 && SICK_WORDS.some(keyword => asLower.includes(keyword.toLowerCase())))
    return false;
  
  // valid otherwise
  else
    return true;
}

export {
  notEmpty,
  validNumber,
  validEmail,
  validPhone,
  validateReason
}