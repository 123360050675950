export const DOG_BREEDS = [
  "Affenpinscher",
  "Afghan Hound",
  "Aidi",
  "Airedale Terrier",
  "Akbash Dog",
  "Alano Español",
  "Alaskan Klee Kai",
  "Alaskan Malamute",
  "Alpine Dachsbracke",
  "Alpine Spaniel",
  "American Bulldog",
  "American Cocker Spaniel",
  "American Eskimo Dog",
  "American Foxhound",
  "American Hairless Terrier",
  "American Pit Bull Terrier",
  "American Staffordshire Terrier",
  "American Water Spaniel",
  "Anglo-Français de Petite Vénerie",
  "Appenzeller Sennenhund",
  "Ariege Pointer",
  "Ariegeois",
  "Armant",
  "Armenian Gampr dog",
  "Artois Hound",
  "Australian Cattle Dog",
  "Australian Kelpie",
  "Australian Shepherd",
  "Australian Silky Terrier",
  "Australian Stumpy Tail Cattle Dog",
  "Australian Terrier",
  "Azawakh",
  "Bakharwal Dog",
  "Barbet",
  "Basenji",
  "Basque Shepherd Dog",
  "Basset Artésien Normand",
  "Basset Bleu de Gascogne",
  "Basset Fauve de Bretagne",
  "Basset Hound",
  "Bavarian Mountain Hound",
  "Beagle",
  "Beagle-Harrier",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Shepherd Dog (Groenendael)",
  "Belgian Shepherd Dog (Laekenois)",
  "Belgian Shepherd Dog (Malinois)",
  "Bergamasco Shepherd",
  "Berger Blanc Suisse",
  "Berger Picard",
  "Berner Laufhund",
  "Bernese Mountain Dog",
  "Billy",
  "Black and Tan Coonhound",
  "Black and Tan Virginia Foxhound",
  "Black Norwegian Elkhound",
  "Black Russian Terrier",
  "Bloodhound",
  "Blue Lacy",
  "Blue Paul Terrier",
  "Boerboel",
  "Bohemian Shepherd",
  "Bolognese",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Ardennes",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Bracco Italiano",
  "Braque d'Auvergne",
  "Braque du Bourbonnais",
  "Braque du Puy",
  "Braque Francais",
  "Braque Saint-Germain",
  "Brazilian Terrier",
  "Briard",
  "Briquet Griffon Vendéen",
  "Brittany",
  "Broholmer",
  "Bruno Jura Hound",
  "Bucovina Shepherd Dog",
  "Bull and Terrier",
  "Bull Terrier (Miniature)",
  "Bull Terrier",
  "Bulldog",
  "Bullenbeisser",
  "Bullmastiff",
  "Bully Kutta",
  "Burgos Pointer",
  "Cairn Terrier",
  "Canaan Dog",
  "Canadian Eskimo Dog",
  "Cane Corso",
  "Cardigan Welsh Corgi",
  "Carolina Dog",
  "Carpathian Shepherd Dog",
  "Catahoula Cur",
  "Catalan Sheepdog",
  "Caucasian Shepherd Dog",
  "Cavalier King Charles Spaniel",
  "Central Asian Shepherd Dog",
  "Cesky Fousek",
  "Cesky Terrier",
  "Chesapeake Bay Retriever",
  "Chien Français Blanc et Noir",
  "Chien Français Blanc et Orange",
  "Chien Français Tricolore",
  "Chien-gris",
  "Chihuahua",
  "Chilean Fox Terrier",
  "Chinese Chongqing Dog",
  "Chinese Crested Dog",
  "Chinese Imperial Dog",
  "Chinook",
  "Chippiparai",
  "Chow Chow",
  "Cierny Sery",
  "Cimarrón Uruguayo",
  "Cirneco dell'Etna",
  "Clumber Spaniel",
  "Combai",
  "Cordoba Fighting Dog",
  "Coton de Tulear",
  "Cretan Hound",
  "Croatian Sheepdog",
  "Cumberland Sheepdog",
  "Curly Coated Retriever",
  "Cursinu",
  "Cão da Serra de Aires",
  "Cão de Castro Laboreiro",
  "Cão Fila de São Miguel",
  "Dachshund",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Danish Swedish Farmdog",
  "Deutsche Bracke",
  "Doberman Pinscher",
  "Dogo Argentino",
  "Dogo Cubano",
  "Dogue de Bordeaux",
  "Drentse Patrijshond",
  "Drever",
  "Dunker",
  "Dutch Shepherd Dog",
  "Dutch Smoushond",
  "East Siberian Laika",
  "East-European Shepherd",
  "Elo",
  "English Cocker Spaniel",
  "English Foxhound",
  "English Mastiff",
  "English Setter",
  "English Shepherd",
  "English Springer Spaniel",
  "English Toy Terrier (Black &amp; Tan)",
  "English Water Spaniel",
  "English White Terrier",
  "Entlebucher Mountain Dog",
  "Estonian Hound",
  "Estrela Mountain Dog",
  "Eurasier",
  "Field Spaniel",
  "Fila Brasileiro",
  "Finnish Hound",
  "Finnish Lapphund",
  "Finnish Spitz",
  "Flat-Coated Retriever",
  "Formosan Mountain Dog",
  "Fox Terrier (Smooth)",
  "French Bulldog",
  "French Spaniel",
  "Galgo Español",
  "Gascon Saintongeois",
  "German Longhaired Pointer",
  "German Pinscher",
  "German Shepherd",
  "German Shorthaired Pointer",
  "German Spaniel",
  "German Spitz",
  "German Wirehaired Pointer",
  "Giant Schnauzer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Gordon Setter",
  "Gran Mastín de Borínquen",
  "Grand Anglo-Français Blanc et Noir",
  "Grand Anglo-Français Blanc et Orange",
  "Grand Anglo-Français Tricolore",
  "Grand Basset Griffon Vendéen",
  "Grand Bleu de Gascogne",
  "Grand Griffon Vendéen",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greek Harehound",
  "Greenland Dog",
  "Greyhound",
  "Griffon Bleu de Gascogne",
  "Griffon Bruxellois",
  "Griffon Fauve de Bretagne",
  "Griffon Nivernais",
  "Hamiltonstövare",
  "Hanover Hound",
  "Hare Indian Dog",
  "Harrier",
  "Havanese",
  "Hawaiian Poi Dog",
  "Himalayan Sheepdog",
  "Hokkaido",
  "Hovawart",
  "Huntaway",
  "Hygenhund",
  "Ibizan Hound",
  "Icelandic Sheepdog",
  "Indian pariah dog",
  "Indian Spitz",
  "Irish Red and White Setter",
  "Irish Setter",
  "Irish Terrier",
  "Irish Water Spaniel",
  "Irish Wolfhound",
  "Istrian Coarse-haired Hound",
  "Istrian Shorthaired Hound",
  "Italian Greyhound",
  "Jack Russell Terrier",
  "Jagdterrier",
  "Jämthund",
  "Kai Ken",
  "Kaikadi",
  "Kanni",
  "Karelian Bear Dog",
  "Karst Shepherd",
  "Keeshond",
  "Kerry Beagle",
  "Kerry Blue Terrier",
  "King Charles Spaniel",
  "King Shepherd",
  "Kintamani",
  "Kishu",
  "Komondor",
  "Kooikerhondje",
  "Koolie",
  "Korean Jindo Dog",
  "Kromfohrländer",
  "Kumaon Mastiff",
  "Kurī",
  "Kuvasz",
  "Kyi-Leo",
  "Labrador Husky",
  "Labrador Retriever",
  "Lagotto Romagnolo",
  "Lakeland Terrier",
  "Lancashire Heeler",
  "Landseer",
  "Lapponian Herder",
  "Large Münsterländer",
  "Leonberger",
  "Lhasa Apso",
  "Lithuanian Hound",
  "Longhaired Whippet",
  "Löwchen",
  "Mahratta Greyhound",
  "Maltese",
  "Manchester Terrier",
  "Maremma Sheepdog",
  "McNab",
  "Mexican Hairless Dog",
  "Miniature American Shepherd",
  "Miniature Australian Shepherd",
  "Miniature Fox Terrier",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Miniature Shar Pei",
  "Molossus",
  "Montenegrin Mountain Hound",
  "Moscow Watchdog",
  "Moscow Water Dog",
  "Mountain Cur",
  "Mucuchies",
  "Mudhol Hound",
  "Mudi",
  "Neapolitan Mastiff",
  "New Zealand Heading Dog",
  "Newfoundland",
  "Norfolk Spaniel",
  "Norfolk Terrier",
  "Norrbottenspets",
  "North Country Beagle",
  "Northern Inuit Dog",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwegian Lundehund",
  "Norwich Terrier",
  "Old Croatian Sighthound",
  "Old Danish Pointer",
  "Old English Sheepdog",
  "Old English Terrier",
  "Old German Shepherd Dog",
  "Olde English Bulldogge",
  "Otterhound",
  "Pachon Navarro",
  "Paisley Terrier",
  "Pandikona",
  "Papillon",
  "Parson Russell Terrier",
  "Patterdale Terrier",
  "Pekingese",
  "Pembroke Welsh Corgi",
  "Perro de Presa Canario",
  "Perro de Presa Mallorquin",
  "Peruvian Hairless Dog",
  "Petit Basset Griffon Vendéen",
  "Petit Bleu de Gascogne",
  "Phalène",
  "Pharaoh Hound",
  "Phu Quoc ridgeback dog",
  "Picardy Spaniel",
  "Plott Hound",
  "Podenco Canario",
  "Pointer (dog breed)",
  "Polish Greyhound",
  "Polish Hound",
  "Polish Hunting Dog",
  "Polish Lowland Sheepdog",
  "Polish Tatra Sheepdog",
  "Pomeranian",
  "Pont-Audemer Spaniel",
  "Poodle",
  "Porcelaine",
  "Portuguese Podengo",
  "Portuguese Pointer",
  "Portuguese Water Dog",
  "Posavac Hound",
  "Pražský Krysařík",
  "Pudelpointer",
  "Pug",
  "Puli",
  "Pumi",
  "Pungsan Dog",
  "Pyrenean Mastiff",
  "Pyrenean Shepherd",
  "Rafeiro do Alentejo",
  "Rajapalayam",
  "Rampur Greyhound",
  "Rastreador Brasileiro",
  "Rat Terrier",
  "Ratonero Bodeguero Andaluz",
  "Redbone Coonhound",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Rough Collie",
  "Russell Terrier",
  "Russian Spaniel",
  "Russian tracker",
  "Russo-European Laika",
  "Sabueso Español",
  "Saint-Usuge Spaniel",
  "Sakhalin Husky",
  "Saluki",
  "Samoyed",
  "Sapsali",
  "Schapendoes",
  "Schillerstövare",
  "Schipperke",
  "Schweizer Laufhund",
  "Schweizerischer Niederlaufhund",
  "Scotch Collie",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "Segugio Italiano",
  "Seppala Siberian Sleddog",
  "Serbian Hound",
  "Serbian Tricolour Hound",
  "Shar Pei",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Shikoku",
  "Shiloh Shepherd Dog",
  "Siberian Husky",
  "Silken Windhound",
  "Sinhala Hound",
  "Skye Terrier",
  "Sloughi",
  "Slovak Cuvac",
  "Slovakian Rough-haired Pointer",
  "Small Greek Domestic Dog",
  "Small Münsterländer",
  "Smooth Collie",
  "South Russian Ovcharka",
  "Southern Hound",
  "Spanish Mastiff",
  "Spanish Water Dog",
  "Spinone Italiano",
  "Sporting Lucas Terrier",
  "St. Bernard",
  "St. John's water dog",
  "Stabyhoun",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Stephens Cur",
  "Styrian Coarse-haired Hound",
  "Sussex Spaniel",
  "Swedish Lapphund",
  "Swedish Vallhund",
  "Tahltan Bear Dog",
  "Taigan",
  "Talbot",
  "Tamaskan Dog",
  "Teddy Roosevelt Terrier",
  "Telomian",
  "Tenterfield Terrier",
  "Thai Bangkaew Dog",
  "Thai Ridgeback",
  "Tibetan Mastiff",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Tornjak",
  "Tosa",
  "Toy Bulldog",
  "Toy Fox Terrier",
  "Toy Manchester Terrier",
  "Toy Trawler Spaniel",
  "Transylvanian Hound",
  "Treeing Cur",
  "Treeing Walker Coonhound",
  "Trigg Hound",
  "Tweed Water Spaniel",
  "Tyrolean Hound",
  "Vizsla",
  "Volpino Italiano",
  "Weimaraner",
  "Welsh Sheepdog",
  "Welsh Springer Spaniel",
  "Welsh Terrier",
  "West Highland White Terrier",
  "West Siberian Laika",
  "Westphalian Dachsbracke",
  "Wetterhoun",
  "Whippet",
  "White Shepherd",
  "Wire Fox Terrier",
  "Wirehaired Pointing Griffon",
  "Wirehaired Vizsla",
  "Yorkshire Terrier",
  "Šarplaninac",
  "Other",
  "Unknown",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const CAT_BREEDS = [
  "Abyssinian",
  "Aegean",
  "American Bobtail",
  "American Curl",
  "American Shorthair",
  "American Wirehair",
  "Arabian Mau",
  "Asian",
  "Asian Semi-longhair",
  "Australian Mist",
  "Balinese",
  "Bambino",
  "Bengal",
  "Birman",
  "Bombay",
  "Brazilian Shorthair",
  "British Longhair",
  "British Semi-longhair",
  "British Shorthair",
  "Burmese",
  "Burmilla",
  "California Spangled",
  "Chantilly-Tiffany",
  "Chartreux",
  "Chausie",
  "Cheetoh",
  "Colorpoint Shorthair",
  "Cornish Rex",
  "Cymric",
  "Cyprus",
  "Devon Rex",
  "Domestic Short Hair",
  "Domestic Medium Hair",
  "Domestic Long Hair",
  "Donskoy",
  "Dragon Li",
  "Dwarf cat",
  "Egyptian Mau",
  "European Shorthair",
  "Exotic Shorthair",
  "Foldex",
  "German Rex",
  "Havana Brown",
  "Highlander",
  "Himalayan",
  "Japanese Bobtail",
  "Javanese",
  "Khao Manee",
  "Korat",
  "Korean Bobtail",
  "Korn Ja",
  "Kurilian Bobtail",
  "LaPerm",
  "Lykoi",
  "Maine Coon",
  "Manx",
  "Mekong Bobtail",
  "Minskin",
  "Munchkin",
  "Napoleon",
  "Nebelung",
  "Norwegian Forest cat",
  "Ocicat",
  "Ojos Azules",
  "Oregon Rex",
  "Oriental Bicolor",
  "Oriental Longhair",
  "Oriental Shorthair",
  "PerFold",
  "Persian (Modern)",
  "Persian (Traditional)",
  "Peterbald",
  "Pixie-bob",
  "Raas",
  "Ragamuffin",
  "Ragdoll",
  "Russian Blue",
  "Russian White, Black and Tabby",
  "Sam Sawet",
  "Savannah",
  "Scottish Fold",
  "Selkirk Rex",
  "Serengeti",
  "Serrade Petit",
  "Siamese",
  "Siberian",
  "Singapura",
  "Snowshoe",
  "Sokoke",
  "Somali",
  "Sphynx",
  "Suphalak",
  "Thai",
  "Thai Lilac",
  "Tonkinese",
  "Toyger",
  "Turkish Angora",
  "Turkish Van",
  "Ukrainian Levkoy",
  "Other",
  "Unknown",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const SICK_WORDS = [
  "itching",
  "allergies",
  "lethargic",
  "vomiting",
  "throwing up",
  "limping",
  "lameness",
  "diarrhea",
  "runny stool",
  "itching ears",
  "ear infection",
  "losing hair",
  "eye infection",
  "eye",
  "not eating",
  "not drinking",
  "coughing",
  "lump",
  "mass",
  "swelling",
  "swollen",
  "sneezing",
  "broken nail",
  "discharge",
  "pain",
  "painful",
  "arthritis",
  "fleas",
  "ticks",
  "tapeworms",
  "scabs",
  "laceration",
  "cut",
  "open wound",
  "broken",
  "fractured",
  "congested",
  "wheezing",
  "out of breath",
  "trouble breathing",
  "trouble urinating",
  "constipated",
  "recheck",
  "x-ray",
  "radiograph",
  "uti",
  "uri",
  "pregnancy",
  "pregnant",
  "abscess",
  "anxiety",
  "Bald",
  "Diabetes",
  "Diabetic",
  "Sensitive",
  "Break",
  "Breaking",
  "Broke",
  "Broken",
  "Knot",
  "Knotted",
  "Asprin",
  "Rotting",
  "Rotten",
  "Overgrooming",
  "Over grooming",
  "Too much grooming",
  "Grooming too much",
  "Under grooming",
  "Not grooming enough",
  "Not feeling well",
  "not doing well",
  "not well",
  "not acting well",
  "not acting right",
  "Euthanasia",
  "put down",
  "put to sleep",
  "Quality of life",
  "End of life",
  "Dying",
  "Distended",
  "Inflated",
  "Enlarged",
  "Large",
  "Growing",
  "Grew",
  "Grown",
  "Excessive",
  "Excess",
  "Gooey",
  "Goo",
  "Goop",
  "Gums",
  "gum",
  "getting worse",
  "gotten worse",
  "Sick",
  "disease",
  "eating litter",
  "Problem",
  "Problems",
  "Rash",
  "Hair loss",
  "hair missing",
  "loss of hair",
  "fur",
  "Blister",
  "Ringworm",
  "Lack of appetite",
  "No appetite",
  "Decrease in appetite",
  "Inappetence",
  "Not drinking",
  "Not eating",
  "Lack of eating",
  "Lack of drinking",
  "Won’t eat",
  "Can’t eat",
  "worm",
  "worms",
  "Mucous",
  "Mucus",
  "Eyes",
  "Shaking Head",
  "Scratching",
  "Scratch",
  "Scratched",
  "Tooth",
  "Teeth",
  "trouble eating",
  "Bloodwork",
  "Urine",
  "Urinating",
  "bladder",
  "Poop",
  "Pooping",
  "Pooped",
  "Bathroom",
  "Stool",
  "Stools",
  "Issue",
  "issues",
  "Irritation",
  "Irritated",
  "Irritating",
  "irritate",
  "Breathing",
  "breathe",
  "breath ",
  "Trouble sleeping",
  "not sleeping",
  "over sleeping",
  "ADR",
  "Rat bait",
  "Roach bait",
  "Bug bait",
  "Poison",
  "Poisoned",
  "Poisoning",
  "Not herself",
  "Not themselves",
  "Not himself",
  "Not acting like herself",
  "Not acting like himself",
  "Not acting like themselves",
  "Not acting like itself",
  "Not itself",
  "Not acting right",
  "Seizure",
  "Seizures",
  "Seizing",
  "spasms",
  "Loss consciousness",
  "not responsive",
  "unconscious",
  "not conscious",
  "unresponsive",
  "Hernia",
  "Herpes",
  "FIV",
  "FIP",
  "Lame",
  "Lameness",
  "hard time walking",
  "hard time running",
  "Hip dysplasia",
  "Elbow dysplasia",
  "Threw up",
  "Throwing up",
  "Throw up",
  "Regurgitation",
  "Regurgitate",
  "regurgitated",
  "Hurt",
  "Hurting",
  "Allergy",
  "Allergic",
  "injured",
  "injure",
  "injury",
  "inflamed",
  "sore",
  "pain",
  "ache",
  "really bad",
  "bad",
  "Bleeding",
  "Bloody",
  "blood",
  "growth",
  "Ooze",
  "Oozing",
  "Oozed",
  "Damage",
  "Damaged",
  "Torn",
  "Tear",
  "Tearing",
  "Gagging",
  "throat",
  "choking",
  "Licking",
  "chewing",
  "Fever",
  "Lung",
  "Lungs",
  "Paw Pad",
  "Paw",
  "Paws",
  "Paw pads",
  "Abnormal",
  "Not Normal",
  "Drain",
  "Drainage",
  "Draining",
  "drained",
  "Wound",
  "Wounds",
  "Wounded",
  "Irregular",
  "Not regular",
  "Cut",
  "Cuts",
  "Cutting",
  "Odor",
  "Stitches",
  "Sutures",
  "suture",
  "Swelled",
  "swell",
  "Hiding",
  "Acting strange",
  "acting weird",
  "Vomiting ",
  "vomit",
  "Mange",
  "mangey",
  "scabies",
  "demodex",
  "Mites",
  "Mite",
  "Fungus",
  "fungal",
  "Yeast",
  "Hair falling out",
  "matt",
  "matted",
  "Mat",
  "mats",
  "matts",
  "cataract",
  "cataracts",
  "Infected",
  "infection",
  "Bite",
  "Biting",
  "Bit",
  "Losing weight",
  "weight loss",
  "lost weight",
  "skinny",
  "emaciated",
  "dead",
  "Arthritic",
  "Can’t walk",
  "Cant walk",
  "Wont walk",
  "Won’t walk",
  "Pale",
  "Cold",
  "Whine",
  "whining",
  "Limp",
  "stiff",
  "Itch",
  "itchy",
  "scooting",
  "Aggressive",
  "caution",
  "Change in behavior",
  "Bandage",
  "rebandage",
  "Bump",
  "bumps",
  "mass",
  "masses",
  "lumps",
  "spot",
  "Scab",
  "scabbing",
  "scabbed",
  "scabs",
  "scabby",
  "Tumor",
  "Vaccine Reaction",
  "Cancer",
  "Illness",
  "Incontinent",
  "incontinence",
  "Constipation",
  "constipated",
  "Rupture",
  "ruptured",
  "rupturing",
  "foreign body",
  "Hot spot",
  "hot spots",
  "Attacked",
  "Hit by car",
  "Ran over",
  "Concerned",
  "Concern",
  "Concerning",
  "Concerns",
  "Redness",
  "Red",
  "vertigo",
  "Senior",
  "X ray",
  "Xray",
  "X-ray",
  "Tests",
  "Lab work",
  "Labwork",
  "Bloodwork",
  "Blood work",
  "health certificate",
];

export const PETCO_DOG_BREEDS = [
  "Unknown",
  "Affenpinscher",
  "Afghan Hound",
  "Afghan Shepherd",
  "Aidi",
  "Airedale Terrier",
  "Akbash Dog",
  "Akita",
  "Alano",
  "Alapaha Blue Blood Bulldog",
  "Alaskan Klee Kai",
  "Alaskan Malamute",
  "Alentejo Mastiff",
  "Alopekis",
  "Alpine Dachsbracke",
  "Alpine Spaniel",
  "American Bandogge Mastiff",
  "American Blue Gascon Hound",
  "American Bulldog",
  "American Bully",
  "American English Coonhound",
  "American Eskimo Dog",
  "American Foxhound",
  "American Hairless Terrier",
  "American Leopard Hound",
  "American Lo-Sze Pugg",
  "American Pit Bull Terrier",
  "American Staffordshire Terrier",
  "American Water Spaniel",
  "Anatolian Shepherd",
  "Anglo-Francais de Petite Venerie",
  "Appenzeller Sennenhunde",
  "Ariege Pointer",
  "Ariegeois",
  "Armant",
  "Artois Hound",
  "Aussie Doodle",
  "Australian Cattle Dog",
  "Australian Kelpie",
  "Australian Koolie",
  "Australian Shepherd",
  "Australian Terrier",
  "Austrian Black & Tan Hound",
  "Austrian Pinscher",
  "Azawakh",
  "Azores Cattle Dog",
  "Barak",
  "Barbet",
  "Basenji",
  "Basset Artesien Normand",
  "Basset Bleu De Gascogne",
  "Basset Fauve De Bretagne",
  "Basset Griffon Vendeen (Grand)",
  "Basset Griffon Vendeen (Petit)",
  "Basset Hound",
  "Bavarian Mountain Hound",
  "Beagle",
  "Beagle Harrier",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Sheepdog",
  "Belgian Shepherd Dog (Groenendael)",
  "Belgian Shepherd Dog (Laekenois)",
  "Belgian Shepherd Dog (Malinois)",
  "Belgian Shepherd Dog (Tervuren)",
  "Bergamasco",
  "Berger Picard",
  "Bernese Mountain Dog",
  "Bichon Frise",
  "Biewer Terrier",
  "Billy",
  "Black and Tan Coonhound",
  "Black Forest Hound",
  "Black Mouth Cur",
  "Black Russian Terrier",
  "Bloodhound",
  "Blue Lacy",
  "Blue Picardy Spaniel",
  "Bluetick Coonhound",
  "Boerboel",
  "Bolognese",
  "Mixed Breed",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Ardennes",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Bracco Italiano",
  "Braque D'Auvergne",
  "Braque du Bourbonnais",
  "Brazilian Mastiff",
  "Brazilian Terrier",
  "Briard",
  "Briquet Griffon Vendeen",
  "Brittany",
  "Broholmer",
  "Brussels Griffon",
  "Bugg",
  "Bull Terrier",
  "Bullmastiff",
  "Burgos Pointer",
  "Cairn Terrier",
  "Canaan Dog",
  "Canadian Eskimo Dog",
  "Canary Island Warren Hound",
  "Cane Corso",
  "Cardigan Welsh Corgi",
  "Carolina Dog",
  "Carpathian Shepherd Dog",
  "Catahoula Leopard Dog",
  "Catalan Sheepdog",
  "Caucasian Ovcharka",
  "Cavachon",
  "Cavalier King Charles Spaniel",
  "Central Asian Shepherd Dog",
  "Cesky Fousek",
  "Cesky Terrier",
  "Chesapeake Bay Retriever",
  "Chihuahua",
  "Chihuahua (Long Coat)",
  "Chihuahua (Smooth Coat)",
  "Chinese Crested",
  "Chinese Shar-Pei",
  "Chinook",
  "Chipoo",
  "Chorkie",
  "Chow Chow",
  "Cimarron Uruguayo",
  "Cirneco dell Etna",
  "Clumber Spaniel",
  "Cockapoo",
  "Cocker Spaniel",
  "Collie",
  "Collie (Rough)",
  "Collie (Smooth)",
  "Coton de Tulear",
  "Croatian Sheepdog",
  "Curly-Coated Retriever",
  "Czechoslovakian Vlcak",
  "Dachshund",
  "Dachshund (Long Hair)",
  "Dachshund (Miniature Long Haired)",
  "Dachshund (Miniature Smooth Haired)",
  "Dachshund (Miniature Wire Haired)",
  "Dachshund (Wire Haired)",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Danish-Swedish Farmdog",
  "Deutscher Wachtelhund",
  "Dingo",
  "Doberman Pinscher",
  "DOG-LARGE-LONGHAIR",
  "DOG-LARGE-SHORTHAIR",
  "DOG-MED-LONGHAIR",
  "DOG-MED-SHORTHAIR",
  "Dogo Argentino",
  "DOG-SMALL-LONGHAIR",
  "DOG-SMALL-SHORTHAIR",
  "Dogue de Bordeaux",
  "DOG-XLG-LONGHAIR",
  "DOG-XLG-SHORTHAIR",
  "Drentsche Patrijshond",
  "Drever",
  "Dunker",
  "Dutch Ratter",
  "Dutch Shepherd",
  "East Siberian Laika",
  "English Bulldog",
  "English Cocker Spaniel",
  "English Foxhound",
  "English Setter",
  "English Shepherd",
  "English Springer Spaniel",
  "English Toy Spaniel",
  "English Toy Terrier (Black & Tan)",
  "Entlebucher Mountain Dog",
  "Estonian Hound",
  "Estrela Mountain Dog",
  "Eurasier",
  "Field Spaniel",
  "Finnish Hound",
  "Finnish Lapphund",
  "Finnish Spitz",
  "Flat-Coated Retriever",
  "French Bulldog",
  "French Pointing Dog - Gascogne type",
  "French Pointing Dog - Pyrenean type",
  "French Spaniel",
  "French Tricolour Hound",
  "French White & Black Hound",
  "French White & Orange Hound",
  "Frisian Water Dog",
  "German Hound",
  "German Longhaired Pointer",
  "German Pinscher",
  "German Roughhaired Pointer",
  "German Shepherd",
  "German Shorthaired Pointer",
  "German Spitz",
  "German Spitz (Klein)",
  "German Spitz (Mittel)",
  "German Wirehaired Pointer",
  "Giant Schnauzer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Goldendoodle",
  "Gordon Setter",
  "Grand Bleu De Gascogne",
  "Grand Gascon-Saintongeois",
  "Grand Griffon Vendeen",
  "Great Anglo-French Tricolour Hound",
  "Great Anglo-French White & Black Hound",
  "Great Anglo-French White & Orange Hound",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greenland Dog",
  "Greyhound",
  "Griffon Bleu de Gascogne",
  "Griffon Fauve de Bretagne",
  "Griffon Nivernais",
  "Halden Hound",
  "Hamiltonstovare",
  "Hanover Hound",
  "Harrier",
  "Havanese",
  "Hellenic Hound",
  "Hokkaido",
  "Hovawart",
  "Hygen Hound",
  "Ibizan Hound",
  "Icelandic Sheepdog",
  "Irish Red and White Setter",
  "Irish Setter",
  "Irish Terrier",
  "Irish Water Spaniel",
  "Irish Wolfhound",
  "Istrian Coarse-Haired Hound",
  "Istrian Short-Haired Hound",
  "Italian Greyhound",
  "Italian Hound",
  "Italian Spitz",
  "Jack Russell Terrier",
  "Jack-A-Poo",
  "Jagdterrier",
  "Japanese Akita Inu",
  "Japanese Chin",
  "Japanese Spitz",
  "Japanese Terrier",
  "Jindo",
  "Kai Ken",
  "Kangal Dog",
  "Karakachan",
  "Karelian Bear Dog",
  "Karst Shepherd Dog",
  "Keeshond",
  "Kerry Beagle",
  "Kerry Blue Terrier",
  "King Charles Spaniel",
  "Kishu Ken",
  "Komondor",
  "Kooikerhondje",
  "Korean Mastiff",
  "Korthals Griffon",
  "Kromfohrlander",
  "Kuvasz",
  "Labradoodle",
  "Labrador Retriever",
  "Lagotto Romagnolo",
  "Lakeland Terrier",
  "Lancashire Heeler",
  "Lapponian Herder",
  "Large Munsterlander",
  "Leonberger",
  "Lhasa Apso",
  "Lhasa Poo",
  "Lowchen",
  "Magyar Agar",
  "Majorca Mastiff",
  "Majorca Shepherd Dog",
  "Maltese",
  "Maltipoo",
  "Manchester Terrier (Standard)",
  "Manchester Terrier (Toy)",
  "Maremma Sheepdog",
  "Mastador",
  "Mastiff",
  "Mi-Ki",
  "Miniature American Shepherd",
  "Miniature Bull Terrier",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Mixed Breed",
  "Mixed Breed Large (61lb +)",
  "Mixed Breed Medium (23 - 60lb)",
  "Mixed Breed Small (up to 22lb)",
  "Montenegrin Mountain Hound",
  "Morkie",
  "Mountain Cur",
  "Mountain Feist",
  "Mudi",
  "Multi-Colored Poodle",
  "Multi-Colored Standard Poodle",
  "Neapolitan Mastiff",
  "New Guinea Singing Dog",
  "Newfoundland",
  "Norfolk Terrier",
  "Norrbottenspets",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwegian Lundehund",
  "Norwich Terrier",
  "Nova Scotia Duck Tolling Retriever",
  "Old Danish Pointing Dog",
  "Old English Sheepdog",
  "Olde English Bulldogge",
  "Otterhound",
  "Papillon",
  "Parson Russell Terrier",
  "Patterdale Terrier",
  "Peekapoo",
  "Pekingese",
  "Pembroke Welsh Corgi",
  "Perro de Presa Canario",
  "Peruvian Inca Orchid",
  "Petit Bleu De Gascogne",
  "Petit Gascon-Saintongeois",
  "Pharaoh Hound",
  "Picardy Sheepdog",
  "Picardy Spaniel",
  "Pit Bull Terrier",
  "Plott",
  "Pointer",
  "Poitevin",
  "Polish Greyhound",
  "Polish Hound",
  "Polish Lowland Sheepdog",
  "Pomapoo",
  "Pomeranian",
  "Pont-Audemer Spaniel",
  'Poodle - Miniature (10"-15")',
  'Poodle - Standard (Over 15")',
  'Poodle - Toy (Under 10")',
  "Porcelaine",
  "Portugese Cattle Dog",
  "Portuguese Podengo",
  "Portuguese Podengo Pequeno",
  "Portuguese Pointer",
  "Portuguese Sheepdog",
  "Portuguese Water Dog",
  "Posavac Hound",
  "Potcake",
  "Pudelpointer",
  "Pug",
  "Puggle",
  "Puli",
  "Pumi",
  "Pungsan",
  "Pyrenean Mastiff",
  "Pyrenean Shepherd",
  "Rafeiro do Alentejo",
  "Rat Terrier",
  "Redbone Coonhound",
  "Rhodesian Ridgeback",
  "Romanian Mioritic Shepherd Dog",
  "Rottweiler",
  "Russell Terrier",
  "Russian Black Terrier",
  "Russian Toy",
  "Russian-European Laika",
  "Saarloose wolfdog",
  "Saint Bernard",
  "Saint Germain Pointer",
  "Saluki",
  "Samoyed",
  "Sarplaninac",
  "Schapendoes",
  "Schiller Hound",
  "Schipperke",
  "Schnauzer (Standard)",
  "Schnoodle",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "Segugio Italiano",
  "Serbian Hound",
  "Serbian Tricolor Hound",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Shihpoo",
  "Shikoku",
  "Shiloh Shepherd",
  "Shorkie",
  "Siberian Husky",
  "Silken Windhound",
  "Silky Terrier",
  "Skye Terrier",
  "Sloughi",
  "Slovakian Rough Haired Pointer",
  "Slovensky Cuvac",
  "Small Munsterlander Pointer",
  "Small Swiss Hound",
  "Smooth Fox Terrier",
  "Soft Coated Wheaten Terrier",
  "South Russian Shepherd Dog",
  "Spanish Greyhound",
  "Spanish Hound",
  "Spanish Mastiff",
  "Spanish Water Dog",
  "Spinone Italiano",
  "Sporting Lucas Terrier",
  "Stabyhoun",
  "Staffordshire Bull Terrier",
  "Staghound",
  "Stephens Cur",
  "Stumpy Tailed Cattle Dog",
  "Styrian Coarse Haired Hound",
  "Sussex Spaniel",
  "Swedish Elkhound",
  "Swedish Lapphund",
  "Swedish Vallhund",
  "Swiss Hound",
  "Teddy Roosevelt Terrier",
  "Thai Ridgeback",
  "Tibetan Mastiff",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Tornjak",
  "Tosa",
  "Toy Fox Terrier",
  "Transylvanian Hound",
  "Treeing Cur",
  "Treeing Feist",
  "Treeing Tennessee Brindle",
  "Treeing Walker Coonhound",
  "Tyrolean Hound",
  "Vizsla",
  "Weimaraner",
  "Welsh Hound",
  "Welsh Springer Spaniel",
  "Welsh Terrier",
  "West Highland White Terrier",
  "West Siberian Laika",
  "Westphalian Dachsbracke",
  "Whippet",
  "White Shepherd",
  "White Swiss Shepherd",
  "Wire Fox Terrier",
  "Wirehaired Pointing Griffon",
  "Wirehaired Vizsla",
  "Wolf Hybrid",
  "Xoloitzcuintli",
  "Yorkipoo",
  "Yorkshire Terrier",
  "Mal-Shi",
].map((b) => ({
  value: b.toLowerCase(),
  label: b,
}));

export const PETCO_CAT_BREEDS = [
  "Unknown",
  "Abyssinian",
  "Aegean",
  "African Serval",
  "American Bobtail",
  "American Bobtail Shorthair",
  "American Curl",
  "American Curl Longhair",
  "American Keuda",
  "American Longhair",
  "American Shorthair",
  "American Wirehair",
  "Applehead Siamese",
  "Ashera",
  "Asian",
  "Asian Semi-longhair",
  "Australian Mist",
  "Bahraini Dilmun",
  "Balinese",
  "Bengal",
  "Bengal Longhair",
  "Birman",
  "Blue Point Himalayan",
  "Bombay",
  "Brazilian Shorthair",
  "Bristol",
  "British Longhair",
  "British Shorthair",
  "Burmese",
  "Burmilla",
  "Burmilla Longhair",
  "California Spangled",
  "Caracat",
  "Chantilly",
  "Chartreux",
  "Chausie",
  "Cheetoh",
  "Chinese Li Hua",
  "Colorpoint Shorthair",
  "Cornish Rex",
  "Cymric",
  "Desert Lynx",
  "Devon Rex",
  "Domestic Longhair",
  "Domestic Mediumhair",
  "Domestic Shorthair",
  "Donskoy",
  "Egyptian Mau",
  "Euro-Chausie",
  "European Burmese",
  "European Shorthair",
  "Exotic Longhair",
  "Exotic Shorthair",
  "German Rex",
  "Havana Brown",
  "Highlander",
  "Highlander Shorthair",
  "Himalayan",
  "Jaguarundi Curl",
  "Japanese Bobtail",
  "Japanese Bobtail Longhair",
  "Javanese",
  "Jungle-Bob",
  "Jungle-Curl",
  "Kashmir",
  "Khaomanee",
  "Korat",
  "Kurilian Bobtail",
  "Kurilian Bobtail Longhair",
  "Laperm",
  "Laperm Shorthair",
  "Lykoi",
  "Machbagral",
  "Maine Coon",
  "Maine Coon Polydactyl",
  "Maltese Cat",
  "Manx",
  "Minskin",
  "Minuet",
  "Minuet Longhair",
  "Mixed Breed",
  "Munchkin",
  "Munchkin Longhair",
  "Nebelung",
  "Norwegian Forest Cat",
  "Ocicat",
  "Ojos Azules",
  "Ojos Azules Longhair",
  "Oriental Longhair",
  "Oriental Shorthair",
  "Owyhee Bob",
  "Pantherette",
  "Persian",
  "Peterbald",
  "Pixiebob",
  "Pixiebob Longhair",
  "Punjabi",
  "RagaMuffin",
  "Ragdoll",
  "Red Point Siamese",
  "Russian Blue",
  "Russian White",
  "Safari",
  "Savannah",
  "Scottish Fold",
  "Scottish Fold Longhair",
  "Scottish Straight",
  "Scottish Striaght Longhair",
  "Selkirk Rex",
  "Selkirk Rex Longhair",
  "Serengeti",
  "Siamese",
  "Siberian",
  "Singapura",
  "Skookum",
  "Snow Tiger Siamese",
  "Snowshoe",
  "Sokoke",
  "Somali",
  "Sphynx",
  "Stone Cougar",
  "Thai",
  "Tonkinese",
  "Toyger",
  "Turkish Angora",
  "Turkish Van",
  "Ukrainian Levkoy",
  "Ussuri",
  "Van Kedisi",
  "Viverral",
  "York Chocolate",
  "Mixed Breed",
].map((b) => ({
  value: b.toLowerCase(),
  label: b,
}));
