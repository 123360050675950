import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { css } from "emotion";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: props.value, valid: false, init: false };
  }

  handleChange = (address) => {
    if (this.props.validate) {
      const valid = this.props.validate(address);
      if (valid !== this.state.valid) {
        this.setState({ valid: valid });

        if (this.props.onValidationChanged)
          this.props.onValidationChanged(valid);
      }
    }

    this.setState({ address });
    this.props.onChange(address);
  };

  componentDidMount() {
    // let callback know of initial validation state
    const valid = this.props.validate(this.state.address);
    if (valid !== this.state.valid) this.setState({ valid: valid });

    if (this.props.onValidationChanged) {
      this.props.onValidationChanged(valid);
    }
  }

  render() {
    const { errorMessage } = this.props;
    const { address, init, valid } = this.state;
    const showValidationError = init && !valid;

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleChange}
        debounce={500}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div
              className={css`
                width: 100%;
                position: relative;
                background-color: #fff;
                padding: 1.3em 1em 0.3em;
                z-index: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                margin-bottom: 10px;
                transition: border-color 0.2s ease;
              `}
            >
              <input
                {...getInputProps({
                  placeholder: this.props.placeholder,
                })}
                className={css`
                  outline: none;
                  font: inherit;
                  color: inherit;
                  background-color: transparent;
                  border: 0;
                  box-shadow: none;
                  width: 100%;
                  z-index: 1;
                `}
                onFocus={() => this.setState({ focus: true })}
                onBlur={() => this.setState({ focus: false, init: true })}
              />
            </div>
            <div
              className={css`
                color: rgba(255, 0, 0);
                font-size: 0.7em;
                display: ${showValidationError ? "block" : "none"};
              `}
            >
              {showValidationError && errorMessage
                ? errorMessage
                : "invalid data"}
            </div>
            {suggestions.length > 0 && (
              <div
                className={css`
                  background-color: #fff;
                  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
                  margin: -5px 10px 0;
                  font-size: 0.93em;
                `}
              >
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? css`
                        padding: 10px 15px;
                        background-color: rgba(0, 0, 0, 0.3);
                      `
                    : css`
                        padding: 10px 15px;
                      `;
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
